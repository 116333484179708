/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button} from 'reactstrap';
import './Buttons.scss';

class LoginButton extends Component {
  render() {
    return <Button className="login-buttons">{this.props.buttontext}</Button>;
  }
}
LoginButton.propTypes = {
  buttontext: PropTypes.any,
};
export default LoginButton;
