/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { Component } from 'react';
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { Helmet } from "react-helmet"


import Navbar from "./NavbarFixed/NavbarFixed";
import Intro from './Intro/Intro';
import StudentCount from './StudentCount/StudentCount';
import Carouselfeedback from './Carouselfeedback/Carouselfeedback';
import StartLearn from './StartLearn/StartLearn';
import Performance from './Performance/Performance';
import Features from './Features/Features';
import Search from './Search/Search';
import Footer from './Footer/Footer';
import "./layout.scss"

class Layout extends Component{

  constructor(props) {
    super(props);
    this.state = {
      color: '',
    };
    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll() {
    // const { prevScrollpos } = this.state;

    const currentScrollPos = window.pageYOffset;
    const hideHeader = currentScrollPos < 5;
    // console.log('scroll', currentScrollPos);
    if (hideHeader) {
      this.setState({
        color: 'nav-hide',
      });
    } else {
      this.setState({
        color: 'nav-color',
      });
    }
  }

  render() {
    const { color } = this.state;
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Siplo</title>
        </Helmet>

        <Navbar color={color} siteTitle="Meetrix" />
        <Intro />
        <StudentCount />
        <Carouselfeedback />
        <Performance />
        <Features />
        <StartLearn />
        <Search />
        <Footer />
      </>
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
