/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './StudentCount.scss';
import CountUp from 'react-countup';

class CountCircles extends Component {
  render() {
    return (
      <div className="circle-wrapper">
        <div className="circle">
          <CountUp
            end={this.props.count}
            duration={5.75}
            delay={3}
            useEasing={false}
          />
          +
        </div>
        <div className="circle-bottom-text">Happy Students</div>
      </div>
    );
  }
}

CountCircles.propTypes = {
  count: PropTypes.any,
};

export default CountCircles;
