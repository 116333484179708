/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import {Col, Row, Button} from 'reactstrap';
// import CarouselSlide from '../CarouselSlideIntro/CarouselSlideIntro';
// import video from '../../../videos/siploIntro.mp4';
import SearchBar from '../SearchBar/serachBar';
import backgroundImg from '../../images/background.svg';
import backgroundVideo from '../../images/background_video.mp4';
import './Intro.scss';
import '../Buttons/Buttons.scss';

class Intro extends Component {
  render() {
    return(
      <div className="intro">
        <video className="background-video" loop autoPlay muted>
          <source src={backgroundVideo} type="video/mp4" />
          <source src={backgroundVideo} type="video/ogg" />
                Your browser does not support the video tag.
        </video>
        <Row className="sides-wrapper">
          <Col xs="12" className="intro-text-wrapper">
            <div className="intro-text">
              <p className="top-text">Hassle Free tuition</p>
              <p className="bottom-text">One-to-One Online tutoring</p>
            </div>
          </Col>
          <Col md="6" sm="12">
            {/* <div className="slide-wrapper side">
              <CarouselSlide />
            </div> */}
            <div className="search-wrapper side">
              <SearchBar />
            </div>
          </Col>
          <Col md="6" />
        </Row>
        {/* <div className="background-video">
          <img src={backgroundImg} alt="background" />
        </div> */}
      </div>
    )
  }
}

export default Intro;
