/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import Arrow from '../../images/forward arrow.svg';
import EditIcon from '../../images/edit.svg';
import ClassIcon from '../../images/class.svg';
import TutorIcon from '../../images/tutor.svg';

import './StartLearn.scss';

class StartLearn extends Component {
  render() {
    return (
      <div className="start-learn">
        <div className="startlearn-heading">Simply start learning</div>
        <Row style={{ justifyContent: 'center' }}>
          <Col lg="3" md="12" className="start-learn-wrapper">
            <div className="start-learn-image">
              <div className="image-wrapper">
                <img src={EditIcon} alt="" />
              </div>
            </div>
            <div className="start-learn-text">
              <div className="top-text">Sign up</div>
              <div className="bottom-text">Within less than 2 minutes</div>
            </div>
          </Col>
          <Col lg="auto" md="12" className="arrow-container">
            <img src={Arrow} alt="" />
          </Col>
          <Col lg="3" md="12" className="start-learn-wrapper">
            <div className="start-learn-image">
              <div className="image-wrapper">
                <img src={ClassIcon} alt="" />
              </div>
            </div>
            <div className="start-learn-text">
              <div className="top-text">Sign up</div>
              <div className="bottom-text">Within less than 2 minutes</div>
            </div>
          </Col>
          <Col lg="auto" md="12" className="arrow-container">
            <img src={Arrow} alt="" />
          </Col>
          <Col lg="3" md="12" className="start-learn-wrapper">
            <div className="start-learn-image">
              <div className="image-wrapper">
                <img src={TutorIcon} alt="" />
              </div>
            </div>
            <div className="start-learn-text">
              <div className="top-text">Sign up</div>
              <div className="bottom-text">Within less than 2 minutes</div>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default StartLearn;
