/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import Proptypes from 'prop-types';
import './FeedbackSlide.scss';

class CountCircles extends Component {
  render() {
    return (
      <div className="feedback-wrapper">
        <div className="profile-picture">
          <img src={this.props.image} alt="profile" />
        </div>
        <div className="text-container">
          <p className="feedback-text">{this.props.feedback}</p>
          <p className="feedback-author">- {this.props.author}</p>
        </div>
      </div>
    );
  }
}
CountCircles.propTypes = {
  author: Proptypes.any,
  feedback: Proptypes.any,
  image: Proptypes.any,
};

export default CountCircles;
