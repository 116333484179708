import React, { useState } from 'react';
import PropTypes from 'prop-types';
import scrollTo from 'gatsby-plugin-smoothscroll';
import 'bootstrap/dist/css/bootstrap.css';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  // UncontrolledDropdown,
  // DropdownToggle,
  // DropdownMenu,
  // DropdownItem,
} from 'reactstrap';
import Buttons from '../Buttons/LoginButton';
import './NavbarFixed.scss';

import Logo from '../../images/siplologo.png';

const NavbarFixed = props => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);
  const login = 'Login';
  const register = 'Register';

  return (
    <div className={props.color} id="nav-fixed">
      <Navbar color="red" light expand="md">
        <NavbarBrand href="/">
          <img src={Logo} alt="logo"/>
        </NavbarBrand>
        <NavbarToggler onClick={toggle} style={{ backgroundColor: 'white'}}/>
        <Collapse isOpen={isOpen} navbar>
          <Nav className="ml-auto" navbar>
            <NavItem>
              <NavLink href={process.env.GATSBY_SIPLO_WEB_URL +"/howitworks"}>How it Works</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href={process.env.GATSBY_SIPLO_WEB_URL +"/tutors"}>Tutors</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href={process.env.GATSBY_SIPLO_WEB_URL +"/register/tutor"}>Become a tutor</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#">Study Materials</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#" onClick={() => scrollTo('#student-count')}>Success Stories</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href={process.env.GATSBY_SIPLO_WEB_URL +"/static/solutions.pdf"}>solutions</NavLink>
            </NavItem>
{/*             
            <NavItem>
              <NavLink href="#">Pricing</NavLink>
            </NavItem> */}
            <NavItem>
              <NavLink href="#" onClick={() => scrollTo('#footer')}>Contact Us</NavLink>
            </NavItem>
            {/* <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
                Options
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem>Option 1</DropdownItem>
                <DropdownItem>Option 2</DropdownItem>
                <DropdownItem divider />
                <DropdownItem>Reset</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown> */}
            <NavItem>
              <NavLink href = {process.env.GATSBY_SIPLO_WEB_URL + "/login"}>
                <Buttons buttontext={login} />
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink href={process.env.GATSBY_SIPLO_WEB_URL +"/register/student"}>
                <Buttons buttontext={register} />
              </NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
    </div>
  );
};
NavbarFixed.propTypes = {
  color: PropTypes.any,
};
export default NavbarFixed;
