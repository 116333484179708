/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
// import Carousel from 'react-bootstrap/Carousel';
import Circles from './CountCircles';
import './StudentCount.scss';

class StudentCount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      count1: '200',
      count2: '500',
      count3: '800',
    };
  }

  render() {
    const { count1, count2, count3 } = this.state;
    return (
      <div className="student-count" id="student-count">
        <div className="note-top">
          <p>Siplo delivers
          <span className="note-color">100+ One to One</span>
          tutpring sessions for the students all around Sri Lanka</p>
        </div>
        <Row className="count-wrapper">
          <Col md="4">
            <Circles count={count1} />
          </Col>
          <Col md="4">
            <Circles count={count2} />
          </Col>
          <Col md="4">
            <Circles count={count3} />
          </Col>
        </Row>
      </div>
    );
  }
}

export default StudentCount;
