/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
// import {Col, Row} from 'reactstrap';
import './Performance.scss';

import Background from '../../images/Best_perfor_background.svg'

class Performance extends Component {
  render() {
    return (
      <div className="performance" style={{ backgroundImage: `URL(${Background})`}}>
        <div className="text-wrapper">
          <span className="heading yellow-text">Best </span>
          <span className="heading">Performance</span>
          <p className="content">
          For the parents who want their child to have the best chance of getting a top grade and also want the ease and convenience of having fantastic,
           reputable tutors at hand regardless of location. Siplo offers a high-quality one-to-one online tutoring platform to help students and tutors work together to identify and build upon learning gaps. Unlike conventional tutoring,
           Siplo's platform offers verified and rated tutors who you can meet for free to ensure they
            are the best match for your learning needs.
          </p>
        </div>
      </div>
    );
  }
}

export default Performance;
