/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
// import PropTypes from 'prop-types';
import ParentIcon from '../../images/user.svg';
import './Footer.scss';

import Logo from '../../images/siplologo.png';

class Footer extends Component {
  render() {
    return (
      <div className="footer" id="footer">
        <Row className="footer-container">
          <Col md="3" className="footer-left">
            <img className="footer-logo-mobile" src={Logo} alt="Logo" />
            <a className="footer-links" href=" ">
              How it Works
            </a>
            <a className="footer-links" href=" ">
              Tutors
            </a>
            <a className="footer-links" href=" ">
              become a Tutors
            </a>
            <a className="footer-links" href=" ">
              Success Stories
            </a>
            <a className="footer-links" href=" ">
              Study Materials
            </a>
            <a className="footer-links" href=" ">
              Pricing
            </a>
          </Col>
          <Col md="9" className="footer-right">
            <div  className="footer-right-wrapper" >
              <img className="footer-logo" src={Logo} alt="Logo" />
              <Row>
                <Col md="4" xs="12" className="footer-icon-col">
                  <div>
                    <Row>
                      <Col lg="3" md="12" className="footer-icon-wrapper">
                        <div className="footer-icon">
                          <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="user" class="svg-inline--fa fa-user fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M313.6 304c-28.7 0-42.5 16-89.6 16-47.1 0-60.8-16-89.6-16C60.2 304 0 364.2 0 438.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-25.6c0-74.2-60.2-134.4-134.4-134.4zM400 464H48v-25.6c0-47.6 38.8-86.4 86.4-86.4 14.6 0 38.3 16 89.6 16 51.7 0 74.9-16 89.6-16 47.6 0 86.4 38.8 86.4 86.4V464zM224 288c79.5 0 144-64.5 144-144S303.5 0 224 0 80 64.5 80 144s64.5 144 144 144zm0-240c52.9 0 96 43.1 96 96s-43.1 96-96 96-96-43.1-96-96 43.1-96 96-96z"></path></svg>
                        </div>
                      </Col>
                      <Col md="9">
                        <p className="footer-contact">
                          +9471 465 2690 (Upekha)
                        </p>
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col md="4" xs="12" className="footer-icon-col">
                  <div>
                    <Row>
                      <Col lg="3" md="12" className="footer-icon-wrapper">
                      <div className="footer-icon" >
                      <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="map-marker-alt" class="svg-inline--fa fa-map-marker-alt fa-w-12" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z"></path></svg>
                      </div>
                      </Col>
                      <Col md="9">
                        <p className="footer-contact">
                          No 158-4-P, Jayagath Mawatha, Hokandara North, Sri
                          Lanka
                        </p>
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col md="4" xs="12" className="footer-icon-col">
                  <div>
                    <Row>
                      <Col lg="3" md="12" className="footer-icon-wrapper">
                      <div className="footer-icon">
                      <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="envelope" class="svg-inline--fa fa-envelope fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M464 64H48C21.49 64 0 85.49 0 112v288c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V112c0-26.51-21.49-48-48-48zm0 48v40.805c-22.422 18.259-58.168 46.651-134.587 106.49-16.841 13.247-50.201 45.072-73.413 44.701-23.208.375-56.579-31.459-73.413-44.701C106.18 199.465 70.425 171.067 48 152.805V112h416zM48 400V214.398c22.914 18.251 55.409 43.862 104.938 82.646 21.857 17.205 60.134 55.186 103.062 54.955 42.717.231 80.509-37.199 103.053-54.947 49.528-38.783 82.032-64.401 104.947-82.653V400H48z"></path></svg>
                      </div>
                      </Col>
                      <Col md="9">
                        <p className="footer-contact">admin@siplo.lk</p>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default Footer;
