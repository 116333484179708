/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import { Button} from 'reactstrap';
import './searchBar.scss';
import '../Buttons/Buttons.scss';

class SearchBar extends Component {
  render() {
    return(
    <div className="search-bar">
        <input type="text" placeholder="Enter your exam/ program type" list="searchData" />
        <Button className="search-button" href={process.env.GATSBY_SIPLO_WEB_URL +"/tutors"}>FIND TUTORS</Button>
        <datalist id="searchData">
          <option value="London Exams" />
          <option value="Degree Programs" />
          <option value="Professional Exams" />
          <option value="GCE (Local) Exams" />
          <option value="Language Exams" />
        </datalist>
      </div>
    )
  }
}

export default SearchBar;
