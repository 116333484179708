/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import Feedback from './FeedbackSlide';
import Constants from '../../Constants/constants.json';
import './FeedbackSlide.scss';
import Image from '../../images/profile.png';

class Carouselfeedback extends Component {
  constructor(props) {
    // authors
    const author1 = Constants.feedback_carousel.feedback1.author;
    const author2 = Constants.feedback_carousel.feedback2.author;
    const author3 = Constants.feedback_carousel.feedback3.author;
    // feedback
    const feedback1 = Constants.feedback_carousel.feedback1.feedback;
    const feedback2 = Constants.feedback_carousel.feedback2.feedback;
    const feedback3 = Constants.feedback_carousel.feedback3.feedback;
    super(props);
    this.state = {
      author1,
      author2,
      author3,
      feedback1,
      feedback2,
      feedback3,
    };
  }

  render() {
    const {
      author1,
      author2,
      author3,
      feedback1,
      feedback2,
      feedback3,
    } = this.state;
    return (
      <div className="carousel-feedback">
        <Carousel
          style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
          indicators
        >
          <Carousel.Item>
            <Feedback author={author1} feedback={feedback1} image={Image}/>
          </Carousel.Item>
          <Carousel.Item>
            <Feedback author={author2} feedback={feedback2} image={Image}/>
          </Carousel.Item>
        </Carousel>
      </div>
    );
  }
}

export default Carouselfeedback;
