/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import { Media, Row, Col } from 'reactstrap';
// import PropTypes from 'prop-types';
import ParentIcon from '../../images/tutor.svg';
import LowData from '../../images/low data.svg';
import Payment from '../../images/payment.svg';
import OneToOne from '../../images/one to one.svg';
import './Features.scss';

class Features extends Component {
  render() {
    return (
      <div className="features-wrapper">
        <p className="feature-heading">What makes Siplo unique</p>
        <Row className="features-container">
          <Col md="12" lg="6" className="each-feature">
            <Media>
              <img className="feature-icon" src={OneToOne} alt="" />
              <Media body>
                <Media heading>One to One</Media>
                One to one live tutoring with whiteboard, video, audio and chat
              </Media>
            </Media>
          </Col>
          <Col md="12" lg="6" className="each-feature">
            <Media>
              <img className="feature-icon" src={Payment} alt="" />
              <Media body>
                <Media heading>Mobile Payment</Media>
                You can pay for the classes using your mobile phone
              </Media>
            </Media>
          </Col>
          <Col md="12" lg="6" className="each-feature">
            <Media>
              <img className="feature-icon" src={ParentIcon} alt="" />
              <Media body>
                <Media heading>Parental Monitering</Media>
                Parents can view children's classes real-time or later
              </Media>
            </Media>
          </Col>
          <Col md="12" lg="6" className="each-feature">
            <Media>
              <img className="feature-icon" src={LowData} alt="" />
              <Media body>
                <Media heading>Low Data Consumption</Media>
                Less than 200MB per hour
              </Media>
            </Media>
          </Col>
        </Row>
      </div>
    );
  }
}

export default Features;
