/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import SearchBar from '../SearchBar/serachBar';
import './Search.scss';

class Search extends Component {
  render() {
    return(
      <div className="search">
            <div className="search-text">
                Find the best tutors and start learning today
            </div>
            <SearchBar />
      </div>
    )
  }
}

export default Search;
